import type { LoaderData } from '@ubo/losse-sjedel'
import { useLoaderData, useLosseConfig } from '@ubo/losse-sjedel'
import type { FormDuufProps } from '@ubo/form-duuf'
import { FormDuuf, FormDuufGroup, useFormDuuf } from '@ubo/form-duuf'
import Loading from './Loading'
import Content from './Content'
import clsx from 'clsx'
import { useState } from 'react'
import type { Vacature } from '~/graphql/types'
import { gaAddToCart } from '~/utils/ga'
import useIsMobile from '~/hooks/useIsMobile'

type FormThemes = 'dark' | 'light' | 'dark--alternative' | 'grey'
type Props = Omit<FormDuufProps, 'renderStatus' | 'renderLoading'> & {
  renderStatus?: FormDuufProps['renderStatus']
  renderLoading?: FormDuufProps['renderLoading']
  theme?: FormThemes
  submit?: 'normal' | 'alternative'
}

export default function FormApplyCustom({ data: _data, ...props }: Props) {
  const config = useLosseConfig()
  const isMobile = useIsMobile()
  const data = useLoaderData<LoaderData<Vacature>>()
  const formId = _data.formId
  const apiUrl = `${config.wordpressUrl}/wp-json/gf/v2/forms/${formId}`
  const [canApply, setCanApply] = useState(true)

  const isEngineering = data.page.vakgebieden.nodes && data.page.vakgebieden.nodes.find((vakgebied) => vakgebied.name === 'Engineering')

  const fields = _data
  const fileIndex = fields?.formFields?.nodes?.findIndex((field: { type: string }) => field.type === 'FILEUPLOAD')

  if (fileIndex > -1 && !isMobile) {
    fields.formFields.nodes[fileIndex].isRequired = true
  }

  return (
    <FormDuuf
      apiUrl={apiUrl}
      renderLoading={() => (
        <div className="absolute left-0 top-0 w-full h-full bg-site-base flex--center gap-3 z-10">
          <Loading />
          <p className="text-white">Aan het versturen ...</p>
        </div>
      )}
      renderStatus={({ content, status }) => (
        <>
          <div className="flex--center -mt-[150px] bg-white py-14">
            {status === 'done' ? (
              <div className="bg-site-accent flex--center w-20 h-20 rounded-full box--shadow">
                <svg
                  width="800px"
                  height="800px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-20 h-20 text-white relative left-1"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.0303 8.78039L8.99993 16.8107L5.4696 13.2804L6.53026 12.2197L8.99993 14.6894L15.9696 7.71973L17.0303 8.78039Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center gap-4 text-center">
                <div className="bg-red-500 flex--center w-20 h-20 rounded-full box--shadow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-20 h-20 text-white relative"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18 6L6 18"></path>
                    <path d="M6 6L18 18"></path>
                  </svg>
                </div>

                <strong>Er is iets misgegaan. Controleer je bestandsgrootte of neem contact op met de adviseur hierboven.</strong>
              </div>
            )}
          </div>
          <Content className="content mt-4 text-center" theme="dark">
            {content}
          </Content>
        </>
      )}
      className={clsx(
        ['dark', 'dark--alternative'].includes(props.theme) && 'form--dark',
        props.theme === 'light' && 'form--light',
        props.theme === 'grey' && 'sm:form--grey max-sm:form--dark',
        'relative py-1'
      )}
      generate={false}
      data={fields}
      {...props}
    >
      <div
        onClick={() => {
          gaAddToCart(data.page)
        }}
        className="sm:grid grid-cols-2 gap-x-4"
      >
        <FormDuufGroup hideIds={[7]} />

        {isEngineering && (
          <div className="form-duuf-group form-duuf-group-select">
            <label className="form-duuf-label d-block mb-0" htmlFor="canApply">
              Woonachtig in Nederland<span className="form-duuf-label-mandatory">*</span>
            </label>

            <select
              className="form-duuf-input form-duuf-input-select !w-24"
              id="canApply"
              name="input_7"
              onChange={(e) => setCanApply(e.target.value === 'Ja')}
            >
              <option value="Ja">Ja</option>
              <option value="Nee">Nee</option>
            </select>
          </div>
        )}

        <FormDuufGroup showIds={[7]} />

        {canApply ? (
          <div className={clsx(props.submit === 'alternative' && 'mt-10 flex--center relative')}>
            <Submit theme={props.theme} />
          </div>
        ) : (
          <Content className="children-p:text-red-600 children-p:font-bold">
            {`
            <p>
              Voor deze functie is het noodzakelijk dat jij op dit moment woonachtig bent in Nederland. Helaas kunnen we jouw sollicitatie
              niet in procedure nemen.
            </p>
            <p>Wil je jouw situatie toch bespreken? Neem contact op met de adviseur hierboven.</p>
            `}
          </Content>
        )}
      </div>
    </FormDuuf>
  )
}

function Submit({ theme }: { theme: FormThemes }) {
  const context = useFormDuuf()
  const isValid: boolean =
    context.captchaFilled &&
    context.formik.isSubmitting === false &&
    Object.keys(context.formik.touched).length > 0 &&
    Object.keys(context.formik.errors).length === 0

  return (
    <div className="form-duuf-group form-duuf-group-submit">
      <span className={`form-duuf-submit${!isValid ? ' form-duuf-submit-disabled' : ''}`}>
        <button
          className={clsx(theme === 'dark--alternative' ? 'btn--accent !px-10' : 'btn--conversion')}
          type="submit"
          disabled={!isValid}
        >
          {theme !== 'dark--alternative' && (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="01 align center">
                <path
                  id="Vector"
                  d="M1.94467 6.66874C1.60983 6.76442 1.30577 6.94586 1.06258 7.19513C0.819396 7.44439 0.645508 7.75284 0.558126 8.08994C0.470744 8.42704 0.472897 8.78112 0.564372 9.11713C0.655846 9.45315 0.833472 9.75945 1.07967 10.0057L4.49167 13.4137V19.9997H11.0847L14.5197 23.4297C14.704 23.6157 14.9233 23.7634 15.165 23.8643C15.4066 23.9652 15.6658 24.0173 15.9277 24.0177C16.0998 24.0174 16.2712 23.9952 16.4377 23.9517C16.7746 23.8669 17.0833 23.6947 17.3326 23.4526C17.5819 23.2105 17.763 22.9071 17.8577 22.5727L24.4917 0.020752L1.94467 6.66874ZM2.50067 8.59174L19.5287 3.57175L6.49367 16.5857V12.5857L2.50067 8.59174ZM15.9407 22.0157L11.9137 17.9997H7.91367L20.9467 4.97775L15.9407 22.0157Z"
                  fill="white"
                />
              </g>
            </svg>
          )}

          {context.form.button.text}
        </button>
      </span>
    </div>
  )
}
