import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ContentDefault from './ContentDefault'
import ContentDisciplines from './ContentDisciplines'
import ContentBlock from './ContentBlock'
import ContentColumnsBig from './ContentColumnsBig'
import ContentUspsDetail from './ContentUspsDetail'
import ContentUsps from './ContentUsps'
import ContentVacancyUsps from './ContentVacancyUsps'
import ContentTechSpecs from './ContentTechSpecs'
import ContentOffer from './ContentOffer'
import ContentTitle from './ContentTitle'
import ContentExpertCTA from './ContentExpertCTA'
import ContentVideo from './ContentVideo'
import ContentTitleAlternative from './ContentTitleAlternative'
import ContentFormula from './ContentFormula'
import ContentFaq from './ContentFaq'
import ContentGrid from './ContentGrid'
import ContentStats from './ContentStats'
import ContentCollapsable from './ContentCollapsable'
import ContentExpertCTAAlternative from './ContentExpertCTAAlternative'
import ContentNews from './ContentNews'
import ContentCta from './ContentCta'
import ContentSchedule from './ContentSchedule'
import ContentColumns from './ContentColumns'
import ContentBare from './ContentBare'
import ContentSmallImage from './ContentSmallImage'
import ContentRankings from './ContentRankings'
import ContentQuotes from './ContentQuotes'
import ContentBlockAlternative from './ContentBlockAlternative'
import ContentCTAExtended from './ContentCTAExtended'
import ContentSwitch from './ContentSwitch'
import ContentVideos from './ContentVideos'
import ContentMatchOnboarding from './ContentMatchOnboarding'
import ContentVideoTitle from './ContentVideoTitle'
import ContentExpert from './ContentExpert'
import ContentImage from './ContentImage'
import ContentEnclose from './ContentEnclose'
import ContentTimeline from './ContentTimeline'

export default function ContentShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    content_default: ContentDefault,
    content_disciplines: ContentDisciplines,
    content_block: ContentBlock,
    content_columns_big: ContentColumnsBig,
    content_usps_detail: ContentUspsDetail,
    content_usps: ContentUsps,
    content_vacancy_usps: ContentVacancyUsps,
    content_tech_specs: ContentTechSpecs,
    content_offer: ContentOffer,
    content_title: ContentTitle,
    content_title_alternative: ContentTitleAlternative,
    content_expert_cta: ContentExpertCTA,
    content_video: ContentVideo,
    content_formula: ContentFormula,
    content_faq: ContentFaq,
    content_stats: ContentStats,
    content_collapsable: ContentCollapsable,
    content_grid: ContentGrid,
    content_expert_cta_alternative: ContentExpertCTAAlternative,
    content_news: ContentNews,
    content_cta: ContentCta,
    content_schedule: ContentSchedule,
    content_columns: ContentColumns,
    content_bare: ContentBare,
    content_small_image: ContentSmallImage,
    content_rankings: ContentRankings,
    content_quotes: ContentQuotes,
    content_block_alternative: ContentBlockAlternative,
    content_cta_extended: ContentCTAExtended,
    content_switch: ContentSwitch,
    content_videos: ContentVideos,
    content_match_onboarding: ContentMatchOnboarding,
    content_video_title: ContentVideoTitle,
    content_expert: ContentExpert,
    content_image: ContentImage,
    content_enclose: ContentEnclose,
    content_timeline: ContentTimeline
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
