import { LosseLink, LossePlaatjie, useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import { useEffect } from 'react'
import CopyButton from '~/components/elements/CopyButton'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerExpert({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  // Make it so the browser can't scroll while the banner is open
  const layout = useLosseLayout()

  useEffect(() => {
    layout.setScrollable(false)
  }, [layout])

  return (
    <motion.section
      key={useLocation().pathname}
      initial={{ y: '200px', opacity: 0 }}
      animate={{ y: '0', opacity: 1 }}
      exit={{ y: '200px', opacity: 0 }}
      transition={{ duration: 0.6, ease: 'easeInOut' }}
      data-component="BannerExpert"
      className="fixed z-[1000] h-full w-full left-0 top-0 bg-site-base "
    >
      <div className="container relative h-full flex items-center max-md:overflow-y-scroll">
        <svg
          onClick={() => {
            // Go back to the previous page
            layout.setScrollable(true)

            if (window.history.length > 1) {
              window.history.back()
            } else {
              window.location.href = '/'
            }
          }}
          className="absolute w-8 h-8 md:w-12 md:h-12 top-5 md:top-10 group cursor-pointer right-5 z-20"
          viewBox="0 0 47 47"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="fi-sr-cross" clipPath="url(#clip0_842_16645)">
            <path
              id="Vector"
              d="M26.2687 23.4999L46.4258 3.34274C46.7825 2.97339 46.9799 2.47871 46.9754 1.96524C46.971 1.45177 46.765 0.960599 46.4019 0.597508C46.0388 0.234418 45.5477 0.0284617 45.0342 0.0239998C44.5207 0.0195379 44.0261 0.216927 43.6567 0.573653L23.4996 20.7308L3.34246 0.573653C2.97311 0.216927 2.47843 0.0195379 1.96496 0.0239998C1.45149 0.0284617 0.96032 0.234418 0.597229 0.597508C0.234138 0.960599 0.028182 1.45177 0.0237201 1.96524C0.0192582 2.47871 0.216647 2.97339 0.573374 3.34274L20.7305 23.4999L0.573374 43.657C0.206243 44.0242 0 44.5222 0 45.0415C0 45.5608 0.206243 46.0588 0.573374 46.4261V46.4261C0.940615 46.7932 1.43864 46.9994 1.95792 46.9994C2.47719 46.9994 2.97522 46.7932 3.34246 46.4261L23.4996 26.2689L43.6567 46.4261C44.0239 46.7932 44.522 46.9994 45.0412 46.9994C45.5605 46.9994 46.0586 46.7932 46.4258 46.4261C46.7929 46.0588 46.9992 45.5608 46.9992 45.0415C46.9992 44.5222 46.7929 44.0242 46.4258 43.657L26.2687 23.4999Z"
              className="fill-white group-hover:fill-site-accent transition-colors duration-200"
            />
          </g>
        </svg>

        <LossePlaatjie src={fields?.relation?.recap?.sinceImage} className="w-24 sm:w-[250px] absolute max-sm:left-3 sm:right-8 top-8" />

        <div className="max-w-screen-lg w-full mx-auto relative max-sm:pt-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-20 xl:gap-10 w-full">
            <h1 className="absolute md:block hidden md:-top-14 lg:-top-20 -left-10 lg:-left-20 text-transparent border-text--white  title--large max-xl:opacity-50 xl:title--unit">
              {fields.relation.title}
            </h1>

            <div className="relative flex flex-wrap justify-center z-10 max-sm:pt-28">
              <LossePlaatjie
                maxwidth={340}
                className="w-[140px] xs:w-[230px] md:w-[300px] lg:w-[320px] animate-fade-up animate-ease-in-out animate-delay-100 "
                src={fields.relation.featuredImage.node}
              />
              {fields.relation.recap.quote && (
                <div className="title--small text-white w-fit text-center relative mx-auto">{fields.relation.recap.quote}</div>
              )}
            </div>
            <div className="md:pt-20">
              <div className="flex sm:justify-start justify-between items-center gap-5">
                <div className="text-white title--medium">{fields.relation.title}</div>
                {fields.relation.recap.linkedin && (
                  <LosseLink target={fields.relation.recap.linkedin.target} to={fields.relation.recap.linkedin.url}>
                    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Linkedin" clipPath="url(#clip0_1128_8664)">
                        <g id="Group">
                          <path
                            id="Vector"
                            d="M0.442383 29.8792H7.95129V9.45264H0.442383V29.8792ZM2.1416 11.2104H6.25207V28.1213H2.1416V11.2104Z"
                            fill="white"
                          />
                          <path
                            id="Vector_2"
                            d="M4.19701 0.121094C1.88273 0.121094 0 2.06881 0 4.46283C0 6.85686 1.88273 8.80463 4.19701 8.80463C6.51129 8.80463 8.39408 6.85691 8.39408 4.46283C8.39408 2.06875 6.51124 0.121094 4.19701 0.121094ZM1.69922 4.46283C1.69922 3.03807 2.81968 1.87891 4.19701 1.87891C5.57434 1.87891 6.69487 3.03801 6.69487 4.46283C6.69487 5.88766 5.57434 7.04682 4.19701 7.04682C2.81968 7.04682 1.69922 5.8876 1.69922 4.46283Z"
                            fill="white"
                          />
                          <path
                            id="Vector_3"
                            d="M28.9815 15.8668C28.7143 11.891 25.5084 8.82715 21.5339 8.82715C19.7561 8.82715 18.0779 9.46652 16.7556 10.601V9.45258H9.56055V29.8791H17.0695V18.7074C17.0695 17.4483 18.0596 16.424 19.2768 16.424C20.4938 16.424 21.484 17.4483 21.484 18.7079L21.4913 29.8792H28.9997V15.8857L28.9815 15.8668ZM15.3703 28.1214H11.2598V11.2104H15.0563V13.721L16.416 13.7291L16.6686 13.3234C17.7363 11.6087 19.5551 10.585 21.5339 10.585C24.6965 10.585 27.2287 13.0812 27.2987 16.2677L27.3004 28.1214H23.1894L23.1832 18.7074C23.1832 16.4791 21.4308 14.6662 19.2768 14.6662C17.1227 14.6662 15.3703 16.4791 15.3703 18.7074V28.1214Z"
                            fill="white"
                          />
                        </g>
                      </g>
                    </svg>
                  </LosseLink>
                )}
              </div>
              <div className="title--normal pt-1 sm:pt-3 text-site-accent !font-light">
                {fields.relation.recap.function.replace('Operators', 'Operations')}
              </div>
              <div className="mt-8 md:mt-12 lg:mt-16">
                <div className="grid grid-cols-2 gap-3 sm:gap-4 max-w-[360px]">
                  {fields.relation.recap.phone && (
                    <CopyButton
                      className="btn--outline--white"
                      value={
                        <div className="flex items-center gap-3">
                          <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="fi-rr-smartphone" clipPath="url(#clip0_842_16591)">
                              <path
                                id="Vector"
                                d="M14.7112 0.494873H9.13717C7.9057 0.496348 6.72508 0.986203 5.8543 1.85699C4.98352 2.72777 4.49366 3.90838 4.49219 5.13986V18.1458C4.49366 19.3773 4.98352 20.5579 5.8543 21.4287C6.72508 22.2995 7.9057 22.7893 9.13717 22.7908H14.7112C15.9426 22.7893 17.1232 22.2995 17.994 21.4287C18.8648 20.5579 19.3547 19.3773 19.3561 18.1458V5.13986C19.3547 3.90838 18.8648 2.72777 17.994 1.85699C17.1232 0.986203 15.9426 0.496348 14.7112 0.494873V0.494873ZM9.13717 2.35287H14.7112C15.4503 2.35287 16.1592 2.64649 16.6819 3.16916C17.2045 3.69182 17.4981 4.4007 17.4981 5.13986V15.3588H6.35018V5.13986C6.35018 4.4007 6.64381 3.69182 7.16647 3.16916C7.68913 2.64649 8.39802 2.35287 9.13717 2.35287ZM14.7112 20.9328H9.13717C8.39802 20.9328 7.68913 20.6392 7.16647 20.1165C6.64381 19.5938 6.35018 18.885 6.35018 18.1458V17.2168H17.4981V18.1458C17.4981 18.885 17.2045 19.5938 16.6819 20.1165C16.1592 20.6392 15.4503 20.9328 14.7112 20.9328Z"
                                fill="white"
                              />
                              <path
                                id="Vector_2"
                                d="M11.9231 20.0033C12.4362 20.0033 12.8521 19.5873 12.8521 19.0743C12.8521 18.5612 12.4362 18.1453 11.9231 18.1453C11.4101 18.1453 10.9941 18.5612 10.9941 19.0743C10.9941 19.5873 11.4101 20.0033 11.9231 20.0033Z"
                                fill="white"
                              />
                            </g>
                          </svg>
                          {`Bel ${fields.relation.recap.firstname}`}
                        </div>
                      }
                      hiddenValue={fields.relation.recap.phone}
                      mobileHiddenValue={`tel:${fields.relation.recap.phone}`}
                      classNameHiddenValue="text-sm"
                    />
                  )}
                  {fields.relation.recap.email && (
                    <CopyButton
                      className="btn--outline--white"
                      value={
                        <div className="flex items-center gap-3">
                          <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="fi-rr-envelope" clipPath="url(#clip0_842_16596)">
                              <path
                                id="Vector"
                                d="M18.4058 1.42358H5.39987C4.16839 1.42506 2.98778 1.91491 2.117 2.7857C1.24621 3.65648 0.756358 4.83709 0.754883 6.06857L0.754883 17.2165C0.756358 18.448 1.24621 19.6286 2.117 20.4994C2.98778 21.3702 4.16839 21.86 5.39987 21.8615H18.4058C19.6373 21.86 20.8179 21.3702 21.6887 20.4994C22.5595 19.6286 23.0493 18.448 23.0508 17.2165V6.06857C23.0493 4.83709 22.5595 3.65648 21.6887 2.7857C20.8179 1.91491 19.6373 1.42506 18.4058 1.42358ZM5.39987 3.28158H18.4058C18.9621 3.28267 19.5053 3.4502 19.9656 3.76262C20.4258 4.07503 20.7821 4.51802 20.9884 5.03459L13.8742 12.1498C13.3506 12.6712 12.6418 12.964 11.9028 12.964C11.1639 12.964 10.4551 12.6712 9.93151 12.1498L2.81726 5.03459C3.02363 4.51802 3.37987 4.07503 3.84012 3.76262C4.30038 3.4502 4.8436 3.28267 5.39987 3.28158ZM18.4058 20.0035H5.39987C4.66071 20.0035 3.95183 19.7099 3.42917 19.1872C2.9065 18.6646 2.61288 17.9557 2.61288 17.2165V7.46206L8.61791 13.4634C9.48989 14.3332 10.6712 14.8216 11.9028 14.8216C13.1345 14.8216 14.3158 14.3332 15.1878 13.4634L21.1928 7.46206V17.2165C21.1928 17.9557 20.8992 18.6646 20.3765 19.1872C19.8539 19.7099 19.145 20.0035 18.4058 20.0035Z"
                                fill="white"
                              />
                            </g>
                          </svg>

                          {`Mail ${fields.relation.recap.firstname}`}
                        </div>
                      }
                      hiddenValue={<>{fields.relation.recap.email}</>}
                      mobileHiddenValue={`mailto:${fields.relation.recap.email}`}
                      classNameHiddenValue="text-sm"
                    />
                  )}
                  {fields.relation.recap.whatsapp && (
                    <LosseLink to={fields.relation.recap.whatsapp.url} className="btn--accent col-span-2">
                      <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="WhatsApp">
                          <path
                            id="Vector"
                            d="M0.944336 22.5051L2.50942 16.9661C1.50392 15.2625 0.979312 13.3229 0.979312 11.331C0.979312 5.19785 5.97185 0.209229 12.1098 0.209229C18.2477 0.209229 23.2403 5.19785 23.2403 11.331C23.2403 17.4641 18.2477 22.4527 12.1098 22.4527C10.195 22.4527 8.32385 21.9635 6.66258 21.0374L0.944336 22.5051ZM6.95986 19.0105L7.30086 19.2202C8.74354 20.1026 10.4048 20.5656 12.1098 20.5656C17.2072 20.5656 21.3517 16.4244 21.3517 11.331C21.3517 6.23751 17.2072 2.09634 12.1098 2.09634C7.01232 2.09634 2.8679 6.23751 2.8679 11.331C2.8679 13.1045 3.37503 14.8256 4.32807 16.3109L4.5554 16.6691L3.65482 19.8579L6.95986 19.0105Z"
                            fill="white"
                          />
                          <path
                            id="Vector_2"
                            d="M8.84222 5.56702L8.0922 5.51863C7.85725 5.50895 7.63133 5.58638 7.4506 5.7509C7.08915 6.08964 6.51082 6.73808 6.33009 7.58976C6.06803 8.8576 6.47467 10.4061 7.53194 11.9546C8.5892 13.5031 10.5682 15.9807 14.0653 17.0357C15.1948 17.3744 16.0804 17.1421 16.7581 16.6776C17.3003 16.3098 17.6708 15.7097 17.8064 15.042L17.9238 14.4419C17.96 14.2483 17.8696 14.0645 17.707 13.9774L15.1948 12.7385C15.0322 12.6611 14.8334 12.7095 14.7249 12.8644L13.7309 14.2387C13.6586 14.3451 13.5321 14.3838 13.4147 14.3354C12.7369 14.0838 10.4597 13.0579 9.21271 10.4835C9.15849 10.3674 9.16753 10.2319 9.24886 10.1448L10.1977 8.97374C10.2971 8.8576 10.3152 8.68339 10.2609 8.53822L9.17657 5.80897C9.12235 5.67348 8.9868 5.5767 8.84222 5.56702Z"
                            fill="white"
                          />
                        </g>
                      </svg>

                      {`Whatsapp ${fields.relation.recap.firstname}`}
                    </LosseLink>
                  )}
                </div>
              </div>
              <div className="mt-6 md:mt-10">
                {fields?.relation?.recap?.facts?.map((f, i) => (
                  <div className="text-white relative pl-10 text-sm sm:text-base py-1 font-bold" key={i}>
                    <div className="h-[2px] w-3 bg-site-accent absolute top-4 sm:top-[18px] left-3" />
                    {f.fact}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  )
}
