import Links from '~/components/elements/Links'
import clsx from 'clsx'
import { Fragment } from 'react'
import type { Page_Flexcontent_Flex_Banner, Post } from '~/graphql/types'
import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import useIsVisible from '~/hooks/useIsVisible'

export default function BannerNews({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const [ref, isVisible] = useIsVisible()
  const { page } = useLoaderData<{ page: Post }>()

  return (
    <section data-component="BannerNews" className="relative lg:-mb-20 overflow-hidden">
      <div className="md:absolute -z-10 grid md:grid-cols-2 w-full h-full max-w-[2000px]">
        <div />
        <div className="sm:relative">
          <LossePlaatjie
            maxwidth={2000}
            className="w-full animate-fade-up animate-ease-in-out animate-duration-500 max-sm:h-[250px] sm:!w-[calc(100%_+_90px)] !max-w-[unset] object-cover md:absolute md:right-0 md:top-0 sm:h-[300px] md:h-[415px] lg:h-[580px] xl:h-[600px]"
            src={fields?.image}
          />
        </div>
      </div>
      <div className="sm:container">
        <div className="grid grid-cols-1 md:grid-cols-4 h-full">
          <div className="md:col-span-3 xl:col-span-2 md:pt-[50px] lg:pt-[100px]">
            <div
              ref={ref}
              className={clsx(
                isVisible && 'animate-fade-right animate-once animate-delay-100 block',
                isVisible ? 'opacity-100' : 'opacity-0',
                'bg-site-base w-full pl-6 sm:pl-4 md:!pl-0 p-8 md:p-12 lg:pb-24 lg:pt-32 lg:pr-32 sm:relative'
              )}
            >
              <div className="flex items-center gap-8">
                {page?.categories?.edges && page?.categories?.edges.length > 0 && (
                  <div className="border-2 border-white rounded-full text-sm py-1 px-6 text-white">
                    {page?.categories?.edges?.[0]?.node?.name}
                  </div>
                )}
                {page?.recap?.readtime && (
                  <div className="flex items-center gap-3 text-white">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="fi-rr-clock" clipPath="url(#clip0_1132_8870)">
                        <path
                          id="Vector"
                          d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z"
                          fill="white"
                        />
                        <path
                          id="Vector_2"
                          d="M11.9995 6C11.7342 6 11.4799 6.10536 11.2924 6.29289C11.1048 6.48043 10.9995 6.73478 10.9995 7V11.325L7.62846 13.437C7.40302 13.5778 7.24277 13.8024 7.18294 14.0614C7.12312 14.3204 7.16863 14.5926 7.30946 14.818C7.45029 15.0434 7.6749 15.2037 7.93389 15.2635C8.19288 15.3233 8.46502 15.2778 8.69046 15.137L12.5305 12.737C12.6755 12.6461 12.7949 12.5195 12.877 12.3692C12.9591 12.219 13.0013 12.0502 12.9995 11.879V7C12.9995 6.73478 12.8941 6.48043 12.7066 6.29289C12.519 6.10536 12.2647 6 11.9995 6Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                    {`${page?.recap?.readtime} ${page?.recap?.readtime === 1 ? ' minuut' : ' minuten'}`}
                  </div>
                )}
              </div>
              <h1 className="text-white mt-5 !leading-[1.1] title--medium">
                {fields?.title?.split(' | ')?.map((row, key) => {
                  return (
                    <Fragment key={key}>
                      {key > 0 && <br />}
                      <span>{row}</span>
                    </Fragment>
                  )
                })}
              </h1>
              <div className="text-site-accent text-xl mt-4">
                {fields?.subtitle?.split(' | ')?.map((row, key) => {
                  return (
                    <Fragment key={key}>
                      {key > 0 && <br />}
                      <span>{row}</span>
                    </Fragment>
                  )
                })}
              </div>
              <Links theme="dark" items={fields?.links} className="mt-10 sm:mt-14" />
              <span className="sm:block hidden absolute top-0 -left-[300px] w-[300px] h-full bg-site-base -z-10" />
              <span className="block md:hidden absolute top-0 -right-[300px] w-[300px] h-full bg-site-base -z-10" />
            </div>
          </div>
          <div />
        </div>
      </div>
    </section>
  )
}
