import { LosseLink, losseContentParse } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Links from '~/components/elements/Links'
import PageDownArrow from '~/components/elements/PageDownArrow'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import useIsVisible from '~/hooks/useIsVisible'

export default function BannerDisciplines({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section
      data-component="BannerDisciplines"
      className="bg-site-base header--margin--top--negative section section--with-bg lg:min-h-[750px] lg:h-[calc(100vh-275px)] relative overflow-hidden"
    >
      <PageDownArrow className="absolute w-5 md:w-7 z-10 left-0 right-0 mx-auto bottom-1 md:bottom-5 animate-bounce animate-ease-in-out" />
      <div className="container lg:min-h-[750px] lg:h-[calc(100vh-275px)] max-lg:header--padding--top">
        <div className="grid lg:grid-cols-2 gap-10 lg:gap-20 text-white h-full lg:-mt-20">
          <div className="h-full flex flex-col justify-center">
            <h1 className="title--semi-large ">Jouw technische team op volle sterkte</h1>
            <div className="title--light spacing--small--top text-site-accent">Dankzij onze unieke Match & Onboardingsformule</div>
            <div className="list--stripe--accent spacing--semi-small--top pl-6">
              <ul>
                <li>Ervaren Adviseurs uit jouw vakgebied die professionals specifiek matchen aan jouw organisatie</li>
                <li>Onboarding specialisten die zorgen dat professionals voor de lange termijn bij jouw organisatie blijven</li>
              </ul>
            </div>
            <div className="spacing--big--top">
              <Links
                items={[
                  {
                    link: { url: '#usps', title: 'Verder lezen' },
                    buttonstyle: 'conversion',
                    microcopy: 'over onze werkwijze'
                  }
                ]}
              />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <Disciplines />
          </div>
        </div>
      </div>
    </section>
  )
}

function Disciplines() {
  const [crosshairRef, crosshairVisible] = useIsVisible()

  const positionMapping = [
    {
      block: 'animate-delay-300 -left-[150px] sm:-left-[200px] -top-[26px] sm:-top-[43px] w-[90px] h-[56px] sm:w-[144px] sm:h-[90px]',
      title: '-left-[30px] -top-[22px] whitespace-nowrap'
    },
    {
      block: 'animate-delay-[400ms] -top-[150px] sm:-top-[200px] -left-[26px] sm:-left-[43px] w-[56px] h-[90px] sm:w-[90px] sm:h-[144px]',
      title: 'left-[30px] top-[30px] whitespace-nowrap'
    },
    {
      block: 'animate-delay-500 -right-[150px] sm:-right-[200px] -top-[26px] sm:-top-[43px] w-[90px] h-[56px] sm:w-[144px] sm:h-[90px]',
      title: 'left-[10px] sm:left-[25px] -bottom-[20px] whitespace-nowrap'
    },
    {
      block:
        'animate-delay-[600ms] -bottom-[150px] sm:-bottom-[200px] -left-[26px] sm:-left-[43px] w-[56px] h-[90px] sm:w-[90px] sm:h-[144px]',
      title: 'left-[30px] bottom-[30px] whitespace-nowrap'
    }
  ]

  const items = [
    { link: { title: 'Service & | Onderhoud', url: '/vakgebieden/service-onderhoud/' } },
    { link: { title: 'Assemblage', url: '/vakgebieden/assemblage/' } },
    { link: { title: 'Engineering', url: '/vakgebieden/engineering/' } },
    { link: { title: 'Operations', url: '/vakgebieden/operations/' } }
  ]

  return (
    <>
      <div
        ref={crosshairRef}
        className="max-sm:min-h-[380px] sm:flex hidden max-lg:min-h-[550px] relative items-center justify-center lg:-ml-10"
      >
        <div className="absolute w-1 h-1  left-0 top-0 right-0 bottom-0 m-auto">
          {items.map((item, i) => (
            <LosseLink className="group" key={i}>
              <div
                className={clsx(
                  crosshairVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                  crosshairVisible ? 'opacity-100' : 'opacity-0',
                  positionMapping[i].block,
                  'absolute bg-site-accent group-hover:bg-site-accent duration-200 transition-all'
                )}
              >
                <div
                  className={clsx(
                    positionMapping[i].title,
                    'absolute text-xl text-white sm:text-3xl font-bold !leading-[1.2] duration-200 transition-all'
                  )}
                >
                  {item.link.title.split('|').map((t, si) => (
                    <span key={si} className="flex items-center gap-3">
                      {losseContentParse(t)}
                      <br />
                      {/* {si === item.link.title.split('|').length - 1 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          className="hidden group-hover:block relative top-[2px]"
                        >
                          <g clipPath="url(#clip0_1123_4357)">
                            <path
                              d="M5.49999 20.6252C5.50003 20.4445 5.53567 20.2656 5.60487 20.0987C5.67408 19.9318 5.7755 19.7802 5.90332 19.6526L12.9351 12.6208C13.1479 12.408 13.3168 12.1554 13.432 11.8773C13.5472 11.5992 13.6065 11.3012 13.6065 11.0002C13.6065 10.6992 13.5472 10.4011 13.432 10.123C13.3168 9.84495 13.1479 9.5923 12.9351 9.37949L5.91157 2.35599C5.66111 2.09666 5.52251 1.74933 5.52565 1.38881C5.52878 1.02829 5.67339 0.683421 5.92832 0.428485C6.18326 0.173548 6.52812 0.0289412 6.88865 0.0258084C7.24917 0.0226756 7.59649 0.161268 7.85582 0.411735L14.8793 7.43524C15.8236 8.38132 16.354 9.66343 16.354 11.0002C16.354 12.3369 15.8236 13.619 14.8793 14.5651L7.84665 21.5977C7.65431 21.7898 7.40933 21.9206 7.14268 21.9736C6.87603 22.0265 6.59968 21.9992 6.34854 21.8951C6.0974 21.791 5.88276 21.6148 5.73172 21.3888C5.58069 21.1627 5.50005 20.897 5.49999 20.6252Z"
                              fill="currentColor"
                            />
                          </g>
                        </svg>
                      )} */}
                    </span>
                  ))}
                </div>
              </div>
            </LosseLink>
          ))}
        </div>
      </div>
      <div className="sm:hidden flex flex-col my-10 gap-3 w-full">
        {items.map((item, i) => (
          <LosseLink className="btn--outline--white text-white text-2xl w-full" key={i}>
            {losseContentParse(item.link.title.replace('|', ''))}
          </LosseLink>
        ))}
      </div>
    </>
  )
}
