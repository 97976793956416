import clsx from 'clsx'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import IframeResizer from 'iframe-resizer-react'
import useIsMobile from '~/hooks/useIsMobile'
import { useEffect, useState } from 'react'

export default function ContentEnclose({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const isMobile = useIsMobile()
  const [init, setInit] = useState(false)

  useEffect(() => {
    if (fields?.type === 'typeform') {
      const script = document.createElement('script')
      script.src = '//embed.typeform.com/next/embed.js'
      script.async = true
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [fields?.type])

  useEffect(() => {
    setInit(true)
  }, [])

  if (!init) return null

  return (
    <section
      data-component="ContentEnclose"
      className={clsx(
        fields?.view !== 'no_space_y' && fields?.view !== 'no_space' && 'section',
        fields?.view === 'no_space_y' && '-my-8 lg:-my-24 xl:-my-28',
        fields?.view === 'no_space' && 'h-[100vh]'
      )}
      id="form"
    >
      <div className={clsx(fields?.view !== 'no_space_x' && fields?.view !== 'no_space' && 'container', 'h-full')}>
        {fields?.type === 'iframe' && (
          <IframeResizer src={fields?.url} className="w-full h-full border-none overflow-hidden" title="Profield" />
        )}
        {fields?.type === 'code' && <div className="h-full w-full" dangerouslySetInnerHTML={{ __html: fields?.code }} />}
        {fields?.type === 'typeform' && (
          <div data-tf-live={fields?.id} style={{ width: '100%', height: isMobile ? '80vh' : '100%', position: 'relative' }}></div>
        )}
        {fields?.type === 'scoreapp' && (
          <IframeResizer
            scrolling
            src={fields?.dataUrl}
            className="w-full h-full border-none overflow-scroll min-h-[850px]"
            title="Profield"
          />
        )}
      </div>
    </section>
  )
}
