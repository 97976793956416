import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import { useState } from 'react'
import type { Expert, Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import { motion } from 'framer-motion'
import useIsMobile from '~/hooks/useIsMobile'
import clsx from 'clsx'
import CopyButton from '~/components/elements/CopyButton'
import Content from '~/components/elements/Content'

export default function ExpertsHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [active, setActive] = useState(0)
  const isXL = useIsMobile('(max-width: 1280px)')
  const isMobile = useIsMobile()

  let sizes = {
    initial: '17%',
    active: '49%',
    panel: fields?.relations?.length > 4 ? '300px' : '370px'
  }

  if (isXL) {
    sizes.initial = '65%'
    sizes.active = '65%'
  }

  if (isMobile) {
    sizes.initial = '100%'
    sizes.active = '100%'
    sizes.panel = 'auto'
  }

  return (
    <section
      data-component="ExpertsHighlighted"
      className={clsx(fields?.hideTitle ? '-mt-10 lg:-mt-[7.5rem] xl:-mt-32' : 'section', 'overflow-hidden')}
      id="uitgelichte-experts"
    >
      <div className="container">
        {!fields?.hideTitle &&
          (fields?.customExpert ? (
            <h2 className="title--large border-text text-transparent mb-10">{fields?.title}</h2>
          ) : (
            <h2 className="title--large border-text text-transparent mb-10">Onze experts</h2>
          ))}

        <div className={clsx(fields?.hideTitle ? 'justify-start' : 'justify-center', 'flex xl:flex-nowrap flex-wrap gap-3 pb-4')}>
          {fields?.customExpert
            ? fields.expert?.map((expert, index) => {
                return (
                  <motion.div
                    onMouseEnter={() => {
                      setActive(index)
                    }}
                    className="flex max-sm:flex-wrap h-auto bg-site-base overflow-hidden"
                    key={index}
                    initial={{ width: sizes.initial }}
                    animate={{ width: active === index ? sizes.active : sizes.initial }}
                  >
                    <div className="p-4">
                      <LossePlaatjie
                        src={expert.image}
                        className="object-cover lg:w-[208px] sm:w-[130px] max-sm:min-w-[100px] w-[100px] h-full flex-none transition-all"
                        alt={expert?.name}
                        maxWidth={208}
                      />
                    </div>
                    <motion.div
                      initial={{ width: index === active || isXL ? sizes.panel : '0px' }}
                      animate={{ width: index === active || isXL ? sizes.panel : '0px' }}
                      className="flex flex-col bg-site-base text-white flex-none overflow-hidden max-sm:max-w-full"
                    >
                      <div className="p-6 flex flex-col lg:w-auto max-sm:max-w-full" style={{ width: sizes.panel }}>
                        <h3 className="title--small">{expert?.name}</h3>
                        <span className="text-site-accent mb-2 sm:mb-8 sm:text-base text-sm line-clamp-1">{expert?.functie}</span>

                        <div className="flex gap-3 mt-4">
                          <Content className="content--dark">{expert?.description}</Content>
                        </div>
                      </div>
                    </motion.div>
                  </motion.div>
                )
              })
            : fields.relations.map((relation, index) => {
                const expert = relation as Expert

                return (
                  <motion.div
                    onMouseEnter={() => {
                      setActive(index)
                    }}
                    className="flex lg:h-[270px] sm:h-[180px] h-[160px]  bg-site-base overflow-hidden"
                    key={index}
                    initial={{ width: sizes.initial }}
                    animate={{ width: active === index ? sizes.active : sizes.initial }}
                  >
                    <img
                      src={expert.featuredImage.node.mediaItemUrl}
                      className="object-cover lg:w-[211px] sm:w-[130px] w-[100px] h-full flex-none transition-all"
                      alt={expert.featuredImage.node.altText}
                    />
                    <motion.div
                      initial={{ width: index === active || isXL ? sizes.panel : '0px' }}
                      animate={{ width: index === active || isXL ? sizes.panel : '0px' }}
                      className="flex flex-col bg-site-base text-white flex-none overflow-hidden"
                    >
                      <div className="p-6 flex flex-col lg:w-auto " style={{ width: sizes.panel }}>
                        <h3 className="title--small">{expert?.title}</h3>
                        <span className="text-site-accent mb-2 sm:mb-8 sm:text-base text-sm line-clamp-1">
                          {expert?.recap?.function.replace('Operators', 'Operations')}
                        </span>

                        <div className="lg:grid grid-cols-2 flex gap-3 mt-4">
                          {expert?.recap?.whatsapp?.title && (
                            <LosseLink to={expert.recap.whatsapp.url} className="btn--accent lg:col-span-2 lg:w-full w-14 lg:px-4 px-0">
                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
                                <path
                                  fill="#fff"
                                  d="M4.327 20.113l1.186-4.198a8.382 8.382 0 01-1.16-4.27c0-4.647 3.783-8.427 8.434-8.427s8.434 3.78 8.434 8.427c0 4.648-3.783 8.428-8.434 8.428A8.469 8.469 0 018.66 19l-4.333 1.113zm4.558-2.649l.258.16a6.973 6.973 0 003.644 1.019c3.863 0 7.003-3.138 7.003-6.998 0-3.86-3.14-6.997-7.003-6.997-3.862 0-7.003 3.138-7.003 6.997 0 1.344.385 2.648 1.107 3.774l.172.271-.682 2.417 2.504-.643z"
                                ></path>
                                <path
                                  fill="#EAEAEB"
                                  d="M10.285 7.23l-.575-.036a.678.678 0 00-.49.178c-.278.259-.72.756-.859 1.408-.2.97.11 2.157.92 3.343s2.326 3.083 5.004 3.891c.865.26 1.543.082 2.062-.274.416-.282.7-.741.803-1.253l.09-.46a.33.33 0 00-.166-.355l-1.924-.949a.288.288 0 00-.36.096l-.761 1.053a.196.196 0 01-.242.074c-.52-.193-2.263-.978-3.218-2.95-.042-.089-.035-.193.027-.26l.727-.896a.339.339 0 00.048-.334l-.83-2.09a.303.303 0 00-.256-.185z"
                                ></path>
                              </svg>

                              <span className="lg:block hidden">{expert.recap.whatsapp.title}</span>
                            </LosseLink>
                          )}

                          {expert?.recap?.phone && (
                            <CopyButton
                              className="btn--outline--white text-sm"
                              value={
                                <>
                                  <svg
                                    className="lg:hidden block"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="18"
                                    viewBox="0 0 12 18"
                                    fill="none"
                                  >
                                    <path
                                      d="M0.289062 0.755371V15.5383C0.289062 16.0984 0.51156 16.6355 0.907607 17.0316C1.30365 17.4276 1.84081 17.6501 2.4009 17.6501H9.44038C10.0005 17.6501 10.5376 17.4276 10.9337 17.0316C11.3297 16.6355 11.5522 16.0984 11.5522 15.5383V0.755371H0.289062ZM10.1443 2.16327V12.0185H1.69696V2.16327H10.1443ZM9.44038 16.2422H2.4009C2.21421 16.2422 2.03515 16.168 1.90314 16.036C1.77112 15.904 1.69696 15.725 1.69696 15.5383V13.4264H10.1443V15.5383C10.1443 15.725 10.0702 15.904 9.93814 16.036C9.80613 16.168 9.62708 16.2422 9.44038 16.2422Z"
                                      fill="white"
                                    />
                                    <path d="M6.62421 14.1299H5.21631V15.5378H6.62421V14.1299Z" fill="white" />
                                  </svg>

                                  <span className="lg:block hidden">
                                    Bel
                                    <span className={clsx(fields?.relations?.length > 4 && 'hidden')}> met {expert.recap.firstname}</span>
                                  </span>
                                </>
                              }
                              hiddenValue={expert?.recap?.phone}
                              mobileHiddenValue={`tel:${expert?.recap?.phone}`}
                              classNameHiddenValue="text-xs"
                            />
                          )}

                          {expert?.recap?.email && (
                            <CopyButton
                              className="btn--outline--white text-sm"
                              value={
                                <>
                                  <svg
                                    className="lg:hidden block"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="17"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                  >
                                    <g clipPath="url(#clip0_1711_897)">
                                      <path
                                        d="M12.9659 1.41797H3.69038C2.81212 1.41902 1.97014 1.76837 1.34911 2.3894C0.72809 3.01042 0.378738 3.8524 0.377686 4.73066L0.377686 12.6811C0.378738 13.5594 0.72809 14.4014 1.34911 15.0224C1.97014 15.6434 2.81212 15.9928 3.69038 15.9938H12.9659C13.8442 15.9928 14.6862 15.6434 15.3072 15.0224C15.9282 14.4014 16.2776 13.5594 16.2786 12.6811V4.73066C16.2776 3.8524 15.9282 3.01042 15.3072 2.3894C14.6862 1.76837 13.8442 1.41902 12.9659 1.41797V1.41797ZM3.69038 2.74305H12.9659C13.3626 2.74383 13.75 2.86331 14.0783 3.08611C14.4065 3.30892 14.6606 3.62485 14.8078 3.99326L9.73406 9.06764C9.36067 9.43953 8.85514 9.64833 8.32815 9.64833C7.80116 9.64833 7.29563 9.43953 6.92224 9.06764L1.84852 3.99326C1.9957 3.62485 2.24976 3.30892 2.57801 3.08611C2.90625 2.86331 3.29366 2.74383 3.69038 2.74305V2.74305ZM12.9659 14.6687H3.69038C3.16323 14.6687 2.65767 14.4593 2.28492 14.0866C1.91217 13.7138 1.70276 13.2083 1.70276 12.6811V5.72447L5.98541 10.0045C6.60729 10.6248 7.44979 10.9731 8.32815 10.9731C9.20651 10.9731 10.049 10.6248 10.6709 10.0045L14.9535 5.72447V12.6811C14.9535 13.2083 14.7441 13.7138 14.3714 14.0866C13.9986 14.4593 13.4931 14.6687 12.9659 14.6687Z"
                                        fill="white"
                                      />
                                    </g>
                                  </svg>

                                  <span className="lg:block hidden">
                                    Mail
                                    <span className={clsx(fields?.relations?.length > 4 && 'hidden')}> met {expert.recap.firstname}</span>
                                  </span>
                                </>
                              }
                              hiddenValue={expert?.recap?.email}
                              mobileHiddenValue={`mailto:${expert?.recap?.email}`}
                              classNameHiddenValue="text-xs"
                            />
                          )}
                        </div>
                      </div>
                    </motion.div>
                  </motion.div>
                )
              })}
        </div>
      </div>
    </section>
  )
}
