import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentCTAExtended({ fields, index }: { fields: Page_Flexcontent_Flex_Content; index: number }) {
  const _function = fields.relation.recap.function.replace('Operators', 'Operations')

  return (
    <section data-component="ContentCTAExtended" className="section section--with-bg bg-site-base text-white" id={fields.sectionId}>
      <div className="container mx-auto">
        <div className="lg:grid flex flex-col grid-cols-2  xl:gap-20 sm:gap-14 gap-8">
          <div>
            {fields.title && <h2 className="xl:title--medium md:text-4xl title--normal font-bold !leading-[1.2]">{fields.title}</h2>}
            {fields.subtitle && (
              <h3 className="xl:title--medium md:text-4xl title--normal font-bold  text-site-accent !leading-[1.2]">{fields.subtitle}</h3>
            )}
          </div>

          <div className="flex flex-col gap-10">
            {fields.description && <Content className="content--dark">{fields.description}</Content>}

            <div className="w-full flex xl:flex-row flex-col xl:gap-10 gap-6 xl:max-w-none max-w-sm mx-auto">
              <div className="max-sm:flex--center">
                <LossePlaatjie
                  className="xl:w-[200px] xl:h-[200px] w-[150px] h-[150px] object-cover object-center"
                  src={fields.relation.featuredImage.node}
                />
              </div>

              <div className="flex flex-col">
                <strong className="text-xl mb-1 max-sm:text-center max-sm:block">{fields.relation.title}</strong>
                <span className="mb-6 max-sm:text-center max-sm:block">
                  {_function.includes(fields.relation.recap.functiongroup[0].name)
                    ? _function
                    : `${_function} ${fields.relation.recap.functiongroup[0].name}`}
                </span>

                {fields.relation.recap.whatsapp.title && (
                  <LosseLink to={fields.relation.recap.whatsapp.url} className="btn--accent w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
                      <path
                        fill="#fff"
                        d="M4.327 20.113l1.186-4.198a8.382 8.382 0 01-1.16-4.27c0-4.647 3.783-8.427 8.434-8.427s8.434 3.78 8.434 8.427c0 4.648-3.783 8.428-8.434 8.428A8.469 8.469 0 018.66 19l-4.333 1.113zm4.558-2.649l.258.16a6.973 6.973 0 003.644 1.019c3.863 0 7.003-3.138 7.003-6.998 0-3.86-3.14-6.997-7.003-6.997-3.862 0-7.003 3.138-7.003 6.997 0 1.344.385 2.648 1.107 3.774l.172.271-.682 2.417 2.504-.643z"
                      ></path>
                      <path
                        fill="#EAEAEB"
                        d="M10.285 7.23l-.575-.036a.678.678 0 00-.49.178c-.278.259-.72.756-.859 1.408-.2.97.11 2.157.92 3.343s2.326 3.083 5.004 3.891c.865.26 1.543.082 2.062-.274.416-.282.7-.741.803-1.253l.09-.46a.33.33 0 00-.166-.355l-1.924-.949a.288.288 0 00-.36.096l-.761 1.053a.196.196 0 01-.242.074c-.52-.193-2.263-.978-3.218-2.95-.042-.089-.035-.193.027-.26l.727-.896a.339.339 0 00.048-.334l-.83-2.09a.303.303 0 00-.256-.185z"
                      ></path>
                    </svg>

                    {fields.relation.recap.whatsapp.title}
                  </LosseLink>
                )}

                <div className="grid grid-cols-2 gap-4 mt-5">
                  <a className="btn--outline--white" href={`tel:${fields.relation.recap.phone}`}>
                    Bel met {fields.relation.recap.firstname}
                  </a>

                  <a className="btn--outline--white" href={`mailto:${fields.relation.recap.email}`}>
                    Mail met {fields.relation.recap.firstname}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
